import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

const Front = lazy(() => import('layout/Front/ContentWrapper'))
const Portal = lazy(() => import('layout/Portal/ContentWrapper'))
const Admin = lazy(() => import('layout/Admin/ContentWrapper'))

export const App = () => {
	return (
		<Suspense fallback={<div />}>
			<Switch>
				<Route path='/admin/' component={Admin} />
				<Route path='/portal/' component={Portal} />
				<Route path='/' component={Front} />
			</Switch>
		</Suspense>
	)
}
