import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { TEAppWrapper } from 'react-tec'
import firebase from 'firebase/app'
import { settings } from 'config/settings'
import validate from 'validate.js'
import { isValidPhoneNumber } from 'react-phone-number-input'

import * as serviceWorker from './serviceWorker'
import 'react-table/react-table.css'
import 'tippy.js/dist/tippy.css'
import './index.css'
import './reactTEC.css'
// ........................... 
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
import { TitleBarProvider, AppProvider, SideNavActiveProvider } from './contexts'

import { theme } from './theme'

import { App } from './App'

const {
	FIREBASE_API_KEY,
	FIREBASE_AUTH_DOMAIN,
	FIREBASE_DATABASE_URL,
	FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET,
	FIREBASE_MESSAGING_SENDER_ID,
} = settings
firebase.initializeApp({
	apiKey: FIREBASE_API_KEY,
	authDomain: FIREBASE_AUTH_DOMAIN,
	databaseURL: FIREBASE_DATABASE_URL,
	projectId: FIREBASE_PROJECT_ID,
	storageBucket: FIREBASE_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
})

const element = (
	<IntlProvider locale='en'>
		<TEAppWrapper theme={theme}>
			<AppProvider>
				<SideNavActiveProvider>
					<TitleBarProvider>
						<App />
					</TitleBarProvider>
				</SideNavActiveProvider>
			</AppProvider>
		</TEAppWrapper>
	</IntlProvider>
)

ReactDOM.render(element, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

//Setup Custom Validators
validate.validators.phone = (value: string) => {
	//If Value is falsy then it should be caught by the required check (if required), so we say it passes otherwise check that it is a valid phone number
	if (!value || isValidPhoneNumber(value)) {
		return
	}

	return 'is an invalid phone number'
}
