export const milliSecondsToHours = (milliSeconds: number, decimalPlaces = 2) => {
	const MILLI_SECONDS_IN_HOUR = 60 * 60 * 1000
	const decimalPlacesMultiplier = 10 * decimalPlaces
	const hours = milliSeconds / MILLI_SECONDS_IN_HOUR
	return Math.round(hours * decimalPlacesMultiplier) / decimalPlacesMultiplier
}

export const daysToMilliSeconds = (days: number) => {
	const MILLI_SECONDS_IN_DAYS = 24 * 60 * 60 * 1000
	return days * MILLI_SECONDS_IN_DAYS
}