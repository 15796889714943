import { Note, Log, UserModels, DisplayNote, DisplayLog } from 'interfaces'

export const generateRandomCode = (length = 2) => {
	let code = ''
	for (let i = 0; i < length; i++) {
		code += s4()
	}
	return code
}

function s4() {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

export const downloadCSV = (csvString: string, fileName: string) => {
	const blob = new Blob([csvString])
	if (
		(window.navigator as any) &&
		(window.navigator as any).msSaveOrOpenBlob &&
		(window.navigator as any).msSaveOrOpenBlob(blob)
	)
		// IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
		(window.navigator as any).msSaveBlob(blob, `${fileName}.csv`)
	else {
		const a = window.document.createElement('a')
		a.href = window.URL.createObjectURL(blob)
		a.download = `${fileName}.csv`
		document.body.appendChild(a)
		a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
		document.body.removeChild(a)
	}
}

export const printPartOfPage = (elementId: string, uniqueIframeId: string) => {
	const content = document.getElementById(elementId)

	if (!content) {
		console.error('No Element Selected', content)
		return
	}

	let pri
	const element = document.getElementById(uniqueIframeId)
	if (element) {
		pri = (element as HTMLIFrameElement).contentWindow
	} else {
		const iframe = document.createElement('iframe')
		iframe.setAttribute('title', uniqueIframeId)
		iframe.setAttribute('id', uniqueIframeId)
		iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
		document.body.appendChild(iframe)
		pri = iframe.contentWindow
	}
	if (pri) {
		pri.document.open()
		pri.document.write(content.innerHTML)
		pri.document.close()
		pri.focus()
		pri.print()
	}
}

export const noteArrayToScrollingData = (
	notesArray: Note[],
	employees?: UserModels,
): DisplayNote[] => {
	return notesArray
		.map((note) => ({
			...note,
			name:
				employees && note.created_by_id && employees[note.created_by_id]
					? employees[note.created_by_id].display_name
					: ' -- ',
		}))
		.sort((a, b) =>
			a.date_created > b.date_created ? 1 : b.date_created > a.date_created ? -1 : 0,
		)
}

export const logArrayToScrollingData = (logArray: Log[], employees?: UserModels): DisplayLog[] => {
	return logArray
		.map((log) => ({
			...log,
			name:
				employees && log.updated_by_id && employees[log.updated_by_id]
					? employees[log.updated_by_id].display_name
					: ' -- ',
		}))
		.sort((a, b) =>
			a.date_created > b.date_created ? 1 : b.date_created > a.date_created ? -1 : 0,
		)
}

export const significantDigitsToSteps = ({
	digits,
	defaultDigits,
	stepNumber = 1,
}: {
	digits: number
	defaultDigits: number
	stepNumber?: number
}): string => {
	if (!digits && !defaultDigits) {
		throw new Error('No Significant Digit or Default Digit Provided')
	}

	const intToStep = (digit: number, stepNumber: number): string => {
		const digitsAsInt = Math.floor(digit)
		let stepString = '0.'
		for (let i = 1; i < digitsAsInt; i++) {
			stepString += '0'
		}
		return (stepString += stepNumber.toString())
	}

	if (digits < 0) {
		console.warn('Negative Significant Digits Provided')
		return intToStep(defaultDigits, stepNumber)
	}

	return intToStep(digits, stepNumber)
}
