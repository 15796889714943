import { settings } from 'config/settings'

export const compareToLocalVersion = (version?: string) => {
	if (!version) {
		return {}
	}

	const { VERSION: localVersion } = settings

	const locationVersionParts = localVersion.split('.')
	const versionParts = version.split('.')

	const major = Number(locationVersionParts[0]) - Number(versionParts[0])
	const minor = Number(locationVersionParts[1]) - Number(versionParts[1])
	const patch = Number(locationVersionParts[2]) - Number(versionParts[2])

	const behind =
		major < 0 || (major === 0 && minor < 0) || (major === 0 && minor === 0 && patch < 0) //Local version is behind input version
	const equal = major === 0 && minor === 0 && patch === 0 //Local Version is the same as input version
	const ahead =
		major > 0 || (major === 0 && minor > 0) || (major === 0 && minor === 0 && patch > 0) //Local version is ahead of input version - Should never happen for "currentVersion"

	return {
		behind,
		equal,
		ahead,
	}
}
