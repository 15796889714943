import { Filter, Column } from 'react-table'

import { FormatDateData, formatDate } from './formatHelper'

//TODO: Improve
export const customTableFilter = (filter: Filter, row: any, column: any): boolean => {
	//TODO: Update and write tests
	const id = filter.pivotId || filter.id
	const rowValue = row[id]

	if (rowValue !== null && (typeof rowValue === 'string' || typeof rowValue === 'number')) {
		return row[id] !== undefined
			? String(rowValue).toLowerCase().includes(filter.value.toLowerCase())
			: true
	}
	return false
}

//TODO: make this accept a generic
interface DateColumnData extends FormatDateData, Column {
	defaultValue?: string
	appendedValue?(d: any): string //TODO: make this a generic
}
export const dateColumn = (data: DateColumnData) => {
	const {
		id,
		Header,
		accessor,
		defaultFormat,
		shiftTimezone,
		options,
		defaultValue = '',
		appendedValue,
		...rest
	} = data

	//TODO: review for type checking on value that comes from row
	return {
		id,
		Header,
		filterMethod: (filter: any, row: any) => {
			const id = filter.pivotId || filter.id
			const rowValue = row[id]
			const appendedString = appendedValue?.(row) ?? ''
			const dateString =
				(formatDate({
					date: rowValue,
					defaultFormat,
					shiftTimezone,
					options,
				}) ?? defaultValue) + `${appendedString ? ` ${appendedString}` : ''}`

			return dateString.toLowerCase().includes(filter.value.toLowerCase())
		},
		accessor,
		//TODO: Make this a generic
		Cell: (d: { original: any; value: number }) => {
			const appendedString = appendedValue?.(d.original) ?? ''
			return (
				(formatDate({
					date: d.value,
					defaultFormat,
					shiftTimezone,
					options,
				}) ?? defaultValue) + `${appendedString ? ` ${appendedString}` : ''}`
			)
		},
		...rest,
	}
}
