import { format, OptionsWithTZ, utcToZonedTime } from 'date-fns-tz'
import { formatPhoneNumber as fPN } from 'react-phone-number-input'

import { TimeZone } from 'config/localData'

const defaultFormats = {
	datetime: 'E, MMM d, yyyy, h:mm a',
	datetimeShort: 'M/d/yyyy, h:mm a',
	date: 'E, MMM d, yyyy',
	dateShort: 'M/d/yyyy',
	time: 'h:mm a',
}
export type DefaultDateFormat = keyof typeof defaultFormats
export interface FormatDateData {
	date?: string | number | Date | null
	formatString?: string
	defaultFormat?: DefaultDateFormat
	options?: OptionsWithTZ
	shiftTimezone?: TimeZone
}
export const formatDate = (data: FormatDateData) => {
	const { date, formatString, defaultFormat, options, shiftTimezone } = data
	let defaultFormatString = defaultFormat ? defaultFormats[defaultFormat] : undefined
	if (options?.timeZone && defaultFormatString) {
		defaultFormatString += ' zzz'
	}
	const formatStringToUse = formatString ?? defaultFormatString

	if (!(date || date === 0) || date < 0 || !formatStringToUse) {
		return ''
	}

	const dateToUse = shiftTimezone ? utcToZonedTime(date, shiftTimezone) : date

	return format(dateToUse, formatStringToUse, options)
}

export const objectOfBoolsToArray = (object?: { [key: string]: boolean }) => {
	if (!object) {
		return []
	}
	const arrayToReturn = []
	for (const key in object) {
		if (object[key]) {
			arrayToReturn.push(key)
		}
	}
	return arrayToReturn
}

export const formatPhoneNumber = (phone?: string, phoneExt?: string) => {
	if (!phone) {
		return ''
	}

	return `${fPN(phone)}${phoneExt ? ` ext. ${phoneExt}` : ''}`
}

export const firstCharacterToUpperCase = (s: string) => s.slice(0, 1).toUpperCase() + s.slice(1);
export const toUSD = (() => {
	const formatter = new Intl.NumberFormat('en-us', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	})
	return (value: any) => {
		if (typeof value !== 'number') return value
		return formatter.format(value)
	}
})()