import { differenceInCalendarDays } from 'date-fns'

import { LineItem, PayItems, PayItem, Tickets, ContractInvoice, ContractEntry } from 'interfaces'
import { TimeZone } from 'config/localData'
import { startOfDayTz } from './dateHelpers'

export interface ContractRemainingLineItem extends LineItem {
	quantity_actual?: number
	quantity_remaining: number
	pay_item_remaining: number
	payItem?: PayItem
}
export interface ContractRemainingLineItems {
	[uid: string]: ContractRemainingLineItem
}

interface RemainingLineItemData {
	lineItemArray: LineItem[]
	tickets?: Tickets
	timestamp?: number
	payItems?: PayItems
	excludedUID?: string
}
export const calculateContractRemainingLineItemArray = (data: RemainingLineItemData) => {
	const { lineItemArray, tickets, timestamp, payItems, excludedUID } = data
	const remainingItems: { [itemUID: string]: ContractRemainingLineItem } = {}

	//Check the item is
	// - A drop off / pick up
	// If timestamp
	// 	- item is not in current ticket
	//	- item is in a ticket before timestamp
	//
	const filteredLineItemArray = lineItemArray.filter(
		(item) =>
			item &&
			item.item_type === 'Drop off / Pick up' &&
			(!timestamp ||
				(timestamp &&
					tickets &&
					tickets[item.job_trans_id] &&
					tickets[item.job_trans_id].uid !== excludedUID &&
					tickets[item.job_trans_id].time_scheduled < timestamp)),
	)
	for (let i = 0; i < filteredLineItemArray.length; i++) {
		const lineItem = filteredLineItemArray[i]
		const {
			item_id,
			contract_pay_item_id,
			job_transaction_type,
			quantity_actual,
			contract_pay_item_quantity,
			billable,
		} = lineItem

		//Complex Key is made up of:
		// - item_id
		// - billable
		// - contract_pay_item_id (optional)
		const complexKey = `${item_id}_${billable}${
			contract_pay_item_id ? `_${contract_pay_item_id}` : ''
		}`
		if (job_transaction_type === 'dropoff' || job_transaction_type === 'laneClosure') {
			if (remainingItems[complexKey]) {
				remainingItems[complexKey].quantity_remaining +=
					quantity_actual && typeof quantity_actual === 'number' ? quantity_actual : 0
				remainingItems[complexKey].pay_item_remaining +=
					contract_pay_item_quantity && typeof contract_pay_item_quantity === 'number'
						? contract_pay_item_quantity
						: 0
			} else {
				remainingItems[complexKey] = {
					...lineItem,
					payItem:
						lineItem.contract_pay_item_id &&
						payItems &&
						payItems[lineItem.contract_pay_item_id]
							? payItems[lineItem.contract_pay_item_id]
							: undefined,
					quantity_remaining:
						quantity_actual && typeof quantity_actual === 'number'
							? quantity_actual
							: 0,
					pay_item_remaining:
						contract_pay_item_quantity && typeof contract_pay_item_quantity === 'number'
							? contract_pay_item_quantity
							: 0,
				}
			}
		} else if (job_transaction_type === 'pickup') {
			if (remainingItems[complexKey]) {
				if (typeof lineItem.quantity_actual === 'number') {
					remainingItems[complexKey].quantity_remaining -= lineItem.quantity_actual
				}
				if (typeof lineItem.contract_pay_item_quantity === 'number') {
					remainingItems[complexKey].pay_item_remaining -=
						lineItem.contract_pay_item_quantity
				}
			} else {
				const newLineItem: any = {
					...lineItem,
					payItem:
						lineItem.contract_pay_item_id &&
						payItems &&
						payItems[lineItem.contract_pay_item_id]
							? payItems[lineItem.contract_pay_item_id]
							: undefined,
					quantity_remaining:
						quantity_actual && typeof quantity_actual === 'number'
							? -quantity_actual
							: 0,
					pay_item_remaining:
						contract_pay_item_quantity && typeof contract_pay_item_quantity === 'number'
							? -contract_pay_item_quantity
							: 0,
				}
				remainingItems[complexKey] = newLineItem
			}
		}
	}
	let remainingItemArray = []
	for (const key in remainingItems) {
		remainingItemArray.push(remainingItems[key])
	}
	remainingItemArray.sort((a, b) =>
		a.name && a.name.toUpperCase() > b.name && b.name.toUpperCase()
			? 1
			: b.name && b.name.toUpperCase() > a.name && a.name.toUpperCase()
			? -1
			: 0,
	)
	return { remainingItems, remainingItemArray }
}

interface GetBilledData {
	invoice?: ContractInvoice
	timezone: TimeZone
}
export const getTotalBilledFromContractInvoice = (data: GetBilledData) => {
	const { invoice, timezone } = data
	const dataToReturn: { [uid: string]: number } = {}
	if (invoice) {
		const { lineItemArray = [], laborTypeArray = [], feeArray = [], payItems = {} } = invoice
console.log('nitesh')
console.log(invoice)

		for (const uid in payItems) {
			const quantity_billed = payItems[uid]
			dataToReturn[uid] = quantity_billed
		}

		for (let i = 0; i < lineItemArray.length; i++) {
			const {
				contract_pay_item_id,
				contract_pay_item_quantity,
				dateDroppedOff,
				datePickedUp,
			} = lineItemArray[i]

			let days = 1

			if (dateDroppedOff && datePickedUp) {
				const startDay = startOfDayTz(dateDroppedOff, timezone)
				const endDay = startOfDayTz(datePickedUp, timezone)
				days = differenceInCalendarDays(endDay, startDay) + 1
			}
			const totalBilled = contract_pay_item_quantity * days
			if (!dataToReturn[contract_pay_item_id]) {
				dataToReturn[contract_pay_item_id] = totalBilled
			} else {
				dataToReturn[contract_pay_item_id] += totalBilled
			}
		}

		for (let i = 0; i < laborTypeArray.length; i++) {
			const { contract_pay_item_id, contract_pay_item_quantity } = laborTypeArray[i]
			if (!dataToReturn[contract_pay_item_id]) {
				dataToReturn[contract_pay_item_id] = contract_pay_item_quantity
			} else {
				dataToReturn[contract_pay_item_id] += contract_pay_item_quantity
			}
		}

		for (let i = 0; i < feeArray.length; i++) {
			const { contract_pay_item_id, quantity } = feeArray[i]
			if (!dataToReturn[contract_pay_item_id]) {
				dataToReturn[contract_pay_item_id] = quantity
			} else {
				dataToReturn[contract_pay_item_id] += quantity
			}
		}
	}
	console.log(dataToReturn)
	return dataToReturn
}

export const getTotalReceivingFromContractInvoice = (receiving?: ContractEntry) => {
	let dataToReturn: { [uid: string]: number } = {}
	if (receiving) {
		const { pay_items_quantity_received, items } = receiving
		if (pay_items_quantity_received) {
			dataToReturn = pay_items_quantity_received
		}

		for (let i = 0; i < items.length; i++) {
			const { uid, quantity } = items[i]
			if (dataToReturn[uid]) {
				dataToReturn[uid] += quantity
			} else {
				dataToReturn[uid] = quantity
			}
		}
	}
	return dataToReturn
}
