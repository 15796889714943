//
// Firebase
//
const DEVELOPMENT_FB = {
	FIREBASE_API_KEY: 'AIzaSyCY-5wUUtid6Yx0rt5jeM4JKlovVvpV8Ko',
	FIREBASE_AUTH_DOMAIN: 'd2k-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://d2k-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'd2k-dev',
	FIREBASE_STORAGE_BUCKET: 'd2k-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '1077941351231',
}
const PRODUCTION_FB = {
	FIREBASE_API_KEY: 'AIzaSyBjeHF2lQxtgK2yMEYRYPdWWqaWRHf50vg',
	FIREBASE_AUTH_DOMAIN: 'd2k-prod.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://d2k-prod.firebaseio.com',
	FIREBASE_PROJECT_ID: 'd2k-prod',
	FIREBASE_STORAGE_BUCKET: 'd2k-prod.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '317771923591',
}
//
// Google Maps
//
const DEVELOPMENT_GOOGLE_MAP = 'AIzaSyDNPjMWn1w085RrbxGoQunPyMUdWHJ1ItE'
const PRODUCTION_GOOGLE_MAP = 'AIzaSyAPW3JgeJsa_BEbkcyqe8_e7hMfX3PcOAA'

//
// Base API
//
// const LOCAL_API = 'http://127.0.0.1:5000'
const LOCAL_API = 'https://cl7bu44qe5.execute-api.us-east-1.amazonaws.com/dev'
const DEVELOPMENT_API = 'https://cl7bu44qe5.execute-api.us-east-1.amazonaws.com/dev'
const PRODUCTION_API = 'https://x2z3owbfu8.execute-api.us-east-1.amazonaws.com/prod'

// 
// AGAVE
//
const link_token = '1LiWt2hZYw3U7gBnHB9uEOjDuDr4214s8SMyikfd'

// 
// Foundation
//
const FOUNDATION_API = {
	username: 'DemoLogin',
	password: 'P9JBm2tHoP',
	sql_host: 'sql.foundationsoft.com',
	sql_port: 9000,
	databaseA: 'Cas_98053_Demo_Phases',
	database2: 'Cas_98053_Demo_No_Phases'
}

const QBD_Sandbox = {
	username: 'qbd-sandbox-6',
	password: 'tfRs!qp4hiCcvVtz',
	registrationCode: 'SLiad+L3ULMQ',
	notes: 'Sent to RCMS on 5/28'
}

//
// Configurations
//
const DEVELOPMENT = {
	BASE_URL: 'http://localhost:3003',
	dev: true,
	apiDebugging: true,
	API_BASE_URL: LOCAL_API,
	GOOGLE_MAP_API: DEVELOPMENT_GOOGLE_MAP,
	...DEVELOPMENT_FB,
	// ...PRODUCTION_FB,
}
const STAGING_DEV = {
	BASE_URL: 'https://staging.rcmssoftware.com',
	dev: true,
	apiDebugging: true,
	API_BASE_URL: DEVELOPMENT_API,
	GOOGLE_MAP_API: DEVELOPMENT_GOOGLE_MAP,
	...DEVELOPMENT_FB,
}
const STAGING_PROD = {
	BASE_URL: 'https://staging.rcmssoftware.com',
	dev: false,
	apiDebugging: true,
	API_BASE_URL: PRODUCTION_API,
	GOOGLE_MAP_API: PRODUCTION_GOOGLE_MAP,
	...PRODUCTION_FB,
}
const PRODUCTION = {
	BASE_URL: 'https://www.rcmssoftware.com',
	dev: false,
	apiDebugging: false,
	API_BASE_URL: PRODUCTION_API,
	GOOGLE_MAP_API: PRODUCTION_GOOGLE_MAP,
	...PRODUCTION_FB,
}

interface ReturnConfirgData {
	staging: 'development' | 'production'
}
const returnConfigData = ({ staging }: ReturnConfirgData) => {
	if (process.env.NODE_ENV === 'development') {
		return DEVELOPMENT
	}
	if (window.location.origin === 'https://staging.rcmssoftware.com') {
		return staging === 'development' ? STAGING_DEV : STAGING_PROD
	}
	return PRODUCTION
}

export const settings = {
	VERSION: '1.1.3',
	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	// ...DEVELOPMENT,
	// ...STAGING_DEV,
	// ...STAGING_PROD,
	// ...PRODUCTION,
	link_token,
	...returnConfigData({ staging: 'development' }),
}
