import { startOfDay, endOfDay } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { subWeeks, startOfWeek } from 'date-fns';
import { TimeZone } from 'config/localData'
import { format, add } from 'date-fns'

export const convertToAgaveDate = (date: number | Date) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'yyyy-MM-dd')
}

export const addDaysToDate = (date: number | Date, period: number) => {
	return add(date, {days: period})
}

export const convertToDateShort = (date: number | Date) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'M/d/yyyy')
}
export const startOfDayTz = (utcDate: string | number | Date, timezone: TimeZone) => {
	const shiftedDate = utcToZonedTime(utcDate, timezone)
	const startOfDayLocal = startOfDay(shiftedDate)
	const startOfDayTz = zonedTimeToUtc(startOfDayLocal, timezone)
	return startOfDayTz
}
export const endOfDayTz = (utcDate: string | number | Date, timezone: TimeZone) => {
	const shiftedDate = utcToZonedTime(utcDate, timezone)
	const endOfDayLocal = endOfDay(shiftedDate)
	const endOfDayTz = zonedTimeToUtc(endOfDayLocal, timezone)
	return endOfDayTz
}

export const startOfLastWeek = () => {
	const now = new Date();
	const lastWeek = subWeeks(now, 1);
	return startOfWeek(lastWeek);
}

export const endOfLastWeek = () => {
	const now = new Date();
	return startOfWeek(now);
}

export const endOfLastMonth = () => {
	const now = new Date()
	const thisYear = now.getFullYear()
	const thisMonth = now.getMonth()
	const lastMonthEnd = new Date(thisYear, thisMonth, 1)
	return lastMonthEnd
}
export const endOfLastYear = () => {
	const now = new Date()
	const thisYear = now.getFullYear()
	const lastYearEnd = new Date(thisYear, 0, 1)
	return lastYearEnd
}