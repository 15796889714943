import axios from 'axios'
import { settings } from 'config/settings'

export const sendEmail = async (data: any) => {
	/*if (settings.dev){
		console.log("Email Sending Blocked On Development")
		return
	}*/

	const response = await axios.post(settings.EMAIL_API_ROUTE, data)
	if (settings.apiDebugging) {
		console.log('Email Sent', response)
	}
	return response
}
